<template>
  <div class="small-font">
    <v-dialog v-model="visible" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline">Update Your Password</v-card-title>
        <v-card-text>
          <v-form v-model="valid" ref="form">
            <!-- Temporary Password (임시 비밀번호) 입력 필드 -->
            <v-text-field
              v-model="tmpPassword"
              label="Enter the temporary password we sent to your email"
              type="password"
              :rules="tmpPasswordRules"
              required
            ></v-text-field>
            <!-- Resend code option -->
            <!-- <div class="forgot"><router-link to="/Forgot">Forgot Password?</router-link></div> -->
            <div class="text-right resend-options">
                <span class="resend-code" @click="resendCode">Resend code</span>
            </div>
            <v-text-field
              v-model="newPassword"
              label="New Password"
              type="password"
              :rules="newPasswordRules"
              required
            ></v-text-field>
            <v-text-field
              v-model="cfmPassword"
              label="Confirm New Password"
              type="password"
              :rules="cfmPasswordRules"
              required
            ></v-text-field>
            <!-- Password requirements message -->
            <div class="password-requirements">
              Be sure to use at least 14 characters, including one letter, one number, and one special character.
            </div>
            <!-- <v-text-field
              v-model="otpCode"
              label="OTP Code"
              type="text"
              :rules="otpRules"
              disabled
            ></v-text-field> -->
            <div v-if="changed" :class="[messagered ? 'messagered' : 'messagegreen']">{{ changed }}</div>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="submitPasswordChange">Update Password</v-btn>
          <v-btn text @click="cancel">Cancel</v-btn>
        </v-card-actions>
      </v-card>
      <!-- Password change success alert -->
      <j-alert
        v-model="msgOpen"
        :type="msgInfo.type"
        :title="msgInfo.title"
        :titleDescription="msgInfo.titleDescription"
        :message="msgInfo.message"
        :button="msgInfo.button"
        :buttonText="msgInfo.buttonText"
        @yes="yes()"
        @cancel="msgOpen = false"
      ></j-alert>
    </v-dialog>
  </div>
</template>

<script>
import __C from '@/primitives/_constant_'
import { AdminService } from "@/services"
import Vue from 'vue'

export default {
  props: {
    visible: { type: Boolean, default: false},
    account: { type: Object, default: () => ({}) }
  },
  data() {
    return {
      tmpPassword: "",  
      newPassword: "",
      cfmPassword: "",
      otpCode: "999999",
      valid: false,
      changed: "",
      messagered: false,
      adminService: new AdminService(),
      tmpPasswordRules: [
        (v) => !!v || "Temporary password is required",
        (v) => v.length <= 14  || "Temporary password must be exactly 14 characters",
      ],
      newPasswordRules: [
        v => !!v || 'Required',
        v => (!!v && v.length >= 14) || 'At leat 14 characters required',
        v => /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*?_]).{14,25}$/.test(v) 
              || '14-25 characters required (at least one number,letter,special char(!@#$%^&*?_)',
      ],
      cfmPasswordRules: [
        v => !!v || 'Required',
        v => (!!v && v.length >= 14) || 'At leat 14 characters required',
        v => /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*?_]).{14,25}$/.test(v) 
              || '14-25 characters required (at least one number,letter,special char(!@#$%^&*?_)',
      ],
      otpRules: [
        (v) => !!v || "OTP is required",
        (v) => /^\d{6}$/.test(v) || "OTP must be exactly 6 digits",
      ],
      msgOpen: false,
      msgInfo: {
        type: "",
        title: "",
        titleDescription: "",
        message: "",
        button: [true, false, true],
        buttonText: ["Yes", "No", "Cancel"]
      },
      yes: () => { }
    }
  },
  methods: {
    async submitPasswordChange() {
      // Validate form inputs
      this.$refs.form.validate();

      const tmpPasswordValid = this.tmpPasswordRules.every((rule) => rule(this.tmpPassword) === true)
      const newPasswordValid = this.newPasswordRules.every((rule) => rule(this.newPassword) === true)
      const cfmPasswordValid = this.cfmPasswordRules.every((rule) => rule(this.cfmPassword) === true)
      const otpValid = this.otpRules.every((rule) => rule(this.otpCode) === true)

      if (!tmpPasswordValid) {
        this.changed = "temp Password does not meet the requirements"
        this.messagered = true
        return
      }

      if (!newPasswordValid) {
        this.changed = "Password does not meet the requirements"
        this.messagered = true
        return
      }

      if (!cfmPasswordValid) {
        this.changed = "Confirmation password does not meet the requirements"
        this.messagered = true
        return
      }

      if (!otpValid) {
        this.changed = "OTP Code does not meet the requirements"
        this.messagered = true
        return
      }
      // new password와 confirm password가 일치하는지 확인
      if (this.newPassword !== this.cfmPassword) {
        this.changed = "Passwords do not match"
        this.messagered = true
        return
      }

      if (this.tmpPassword === this.newPassword) {
        this.changed = "New password cannot be the same as the temporary password"
        this.messagered = true
        return
      }

      // API Call to update password
      //console.log("Updating password...", this.account, this.newPassword, this.otpCode)
      let tmpPassword_enc = await this.setPasswordEnc(this.tmpPassword)
      let newPassword_enc = await this.setPasswordEnc(this.newPassword)
      const selectedItem = { ...this.account, password: newPassword_enc, tmpPassword: tmpPassword_enc, otpCode: this.otpCode }
      this.adminService.updAdminMember(selectedItem, (res) => {
        if (res.data.error) {
          this.changed = res.message
          this.messagered = true
        } else {
          this.changed = "Password updated successfully!"
          this.messagered = false

          this.msgInfo.type = "INFO"
          this.msgInfo.title = "Password Updated"
          this.msgInfo.titleDescription = ""
          this.msgInfo.message = "Your password has been changed successfully. Please log in again."
          this.msgInfo.buttonText[0] = "OK"
          this.msgInfo.button = [true, false, true]
          this.yes = () => {
            this.msgOpen = false
            // 쿠키에서 토큰 삭제
            Vue.$cookies.remove('token')
            // remove user from local storage to sign user out
            localStorage.removeItem(__C.LOCAL_STORAGE_NAME.ACCOUNT)
            localStorage.removeItem(__C.LOCAL_STORAGE_NAME.TOKEN)
            this.$emit("passwordResetSuccess")// Close the dialog after success
          }
          this.msgOpen = true
        }
      })
    },
    cancel() {
      this.$emit("passwordResetCancel")
    },
    async setPasswordEnc(password_) {
      // 비밀번호를 SHA-256으로 해싱
      const passwordBuffer = new TextEncoder().encode(password_)
      const hashedPasswordBuffer = await crypto.subtle.digest('SHA-1', passwordBuffer)
      const hashedPassword = this.arrayBufferToHex(hashedPasswordBuffer).toUpperCase()
      return hashedPassword
    },
    // Utility function to convert ArrayBuffer to hex string
    arrayBufferToHex(buffer) {
      return Array.from(new Uint8Array(buffer))
        .map(b => b.toString(16).padStart(2, '0'))
        .join('')
    },
    resendCode() {
      // 현재 팝업 닫기
      this.$emit("passwordResetForgot") // visible을 false로 설정하여 팝업 닫기
    },
  }
};
</script>

<style scoped>
.small-font {
  font-size: 12px;
  padding-top: 20px; /* Top padding to move everything down */
}

.messagered {
  color: red;
  font-size: 12px;
}

.messagegreen {
  color: green;
  font-size: 12px;
}

.resend-code {
  color: #1e88e5; /* Link color */
  cursor: pointer;
  text-decoration: underline;
}

.resend-options {
  margin-bottom: 20px; /* Add space between resend and the next field */
}

.v-card-text {
  padding-bottom: 10px; /* Adjust padding inside the card */
}

.password-requirements {
  margin-top: 10px;
  font-size: 12px;
  color: #666;
}
</style>
